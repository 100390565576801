import React from 'react';
import styled from 'styled-components';

import GoogleMapsApi from './GoogleMapsApi';

const GoogleMapsWrapper = styled.div`
  position: relative;
  height: 500px;
  margin-bottom: 32px;
`;

const GoogleMapsMap = () => (
  <>
    <GoogleMapsWrapper>
      <GoogleMapsApi />
    </GoogleMapsWrapper>
  </>
);

export default GoogleMapsMap;
