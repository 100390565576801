import styled from 'styled-components';

import mapMarker from '../../../../images/icons/map-marker.svg';
import printer from '../../../../images/icons/printer.svg';

export default styled.button`
  position: relative;
  background-color: #f58021;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 16px;
  height: 64px;

  &:hover {
    color: white;
  }

  @media screen and (min-width: 768px) {
    font-size: 16px;
    margin-top: 0;
  }

  span {
    display: block;
  }

  .MapMarkerIcon,
  .PrinterIcon {
    background-size: cover;
    margin-right: 8px;
    background-repeat: no-repeat;

    @media screen and (min-width: 768px) {
      margin-right: 16px;
    }
  }

  .MapMarkerIcon {
    background-image: url(${mapMarker});
    height: 30px;
    width: 20px;

    @media screen and (min-width: 768px) {
      height: 36px;
      width: 25px;
    }
  }

  .PrinterIcon {
    background-image: url(${printer});
    height: 24px;
    width: 28px;

    @media screen and (min-width: 768px) {
      height: 32px;
      width: 36px;
    }
  }
`;
