import styled from 'styled-components';

import config from '../../../helpers/styles/theme';

const StaticHeroContainer = styled.div`
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding: 150px 16px 24px 16px;
  position: relative;

  @media print {
    padding-top: 100px;
  }

  ${(props) => props.noSideSpacing && 'padding-left: 0; padding-right: 0;'}
  ${(props) => props.hideBlock && 'padding-bottom: 0;'}
  ${(props) => !props.hideBlock && 'overflow: hidden;'}

  @media screen and (min-width: 1541px) {
    padding: 150px 0 24px 0;
    ${(props) => props.hideBlock && 'padding-bottom: 0;'}
  }

  ${(props) => !props.hideBlock && 'min-height: 600px;'}

  .StaticHeroContainer__Link {
    color: #ffffff;

    @media print {
      color: #00254a;
    }

    &:hover {
      color: #f58021;
    }
  }

  .StaticHeroContainer__Title {
    color: #ffffff;
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 50px;
    position: relative;

    @media print {
      color: #00254a;
    }

    h2 {
      font-size: 40px;
    }

    a {
      color: #ffffff;
      font-size: 40px;

      @media print {
        color: #00254a;
      }

      &:hover {
        color: #f58021;
      }
    }
  }

  .StaticHeroContainer__Title {
    a {
      color: #f58021;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .StaticHeroContainer__Foreword {
    background-color: ${({ category }) =>
      `rgba(${config.scheme.rgba[category]})`};
    padding: 24px;
    position: relative;
    height: 100%;
    overflow: hidden;

    @media screen and (min-width: 768px) {
      padding: 42px;
    }

    @media screen and (min-width: 1024px) {
      padding: 42px 72px;
      ${(props) => !props.hideBlock && 'padding-bottom: 0;'}
    }

    @media print {
      padding: 0;
      padding-right: 16px;
    }

    ${(props) =>
      !props.hideBlock &&
      `
      &::after {
        content: '';
        width: 100%;
        height: 600px;
        position: absolute;
        bottom: -600px;
        left: 0;
        background-color: rgba(${config.scheme.rgba[props.category]});
      }
    `}

    h1, h2 {
      color: #f58021;
      font-size: 28px;

      @media print {
        font-size: 20px;
      }
    }

    .StaticHeroContainer__ForewordCopy {
      font-size: 20px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
      word-break: break-word;

      @media screen and (min-width: 1024px) {
        font-size: 24px;
        line-height: 1.5;
      }

      @media print {
        font-size: 16px;

        p {
          font-size: 16px;
        }
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .StaticHeroContainer__Foreword--Generic {
    p {
      color: #6d6e71;
      font-size: 20px;
      line-height: 1.5;

      @media print {
        font-size: 1rem;
      }

      @media screen and (min-width: 1024px) {
        font-size: 24px;
      }
    }

    .StaticHeroContainer__Foreword--Contact-Title {
      font-size: 28px;

      @media screen and (min-width: 768px) {
        font-size: 32px;
      }
    }

    .StaticHeroContainer__Foreword--Staff-Title {
      font-size: 36px;
    }
  }

  .StaticHeroContainer__Foreword--Staff-Quote {
    font-family: henderson-sans-basic;
    color: #6d6e71;
  }

  .StaticHeroContainer__Foreword--Contact {
    padding: 32px;

    @media screen and (min-width: 768px) {
      padding: 54px;
    }
  }

  .StaticHeroContainer__Foreword--Article {
    h2 {
      color: #f58021;
      font-size: 32px;
      text-transform: uppercase;
      font-weight: bold;
    }

    p {
      color: #000000;
      border-bottom: 1px solid #939598;
      font-size: 20px;
      padding-bottom: 12px;
      margin-bottom: 14px;
      margin-right: 24px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin-left: 0;

      li {
        border-bottom: 1px solid #939598;
        color: #000000;
        font-size: 20px;
        padding-bottom: 12px;
        margin-bottom: 14px;
        margin-right: 24px;

        &:last-child {
          border-bottom: none;
        }
      }

      li:only-child {
        border-bottom: none;

        @media screen and (min-width: 1024px) {
          border-bottom: 1px solid #939598;
        }
      }
    }
  }
`;

StaticHeroContainer.defaultProps = {
  hideBlock: false,
  category: 'generic',
};

export default StaticHeroContainer;
