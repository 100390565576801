import React from 'react';
import styled from 'styled-components';

import config from '../../../utils/config';
import { Grid, Cell } from '../../global/grid';
import BlockList from '../list/BlockList';

const StyledAddressGrid = styled.div`
  color: #6d6e71;
  font-size: 22px;
  font-weight: 300;
`;

const AddressGrid = () => (
  <StyledAddressGrid>
    <Grid>
      <Cell cellSize={{ widescreen: 6, desktop: 6, tablet: 6 }}>
        <BlockList size="medium">
          <li>Office Address:</li>
          <li>{config.addressLine1}</li>
          <li>
            {config.town} {config.postcode}
          </li>
        </BlockList>
      </Cell>
      <Cell cellSize={{ widescreen: 6, desktop: 6, tablet: 6 }}>
        <BlockList size="medium">
          <li>Tel: {config.tel}</li>
          <li>Fax: {config.fax}</li>
          <li>Email: {config.email}</li>
        </BlockList>
      </Cell>
    </Grid>
  </StyledAddressGrid>
);

export default AddressGrid;
