import * as React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../../global/container/Container';
import StyledServiceCategorySection from './styles/StyledServiceCategorySection';
import ServiceCard from '../service-card/ServiceCard';
import { Cell, Grid } from '../../global/grid';

const StyledContainer = styled.div`
  @media print {
    display: none;
  }
`;

const StyledSloganText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  padding: 8px;

  @media screen and (min-width: 600px) {
    padding: 2px;
    font-size: 17px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 16px;
    padding: 0;
  }
`;

const SloganText = ({ slogan }) => (
  <StyledSloganText>
    <p>{slogan}</p>
  </StyledSloganText>
);

const loadPhoneNumber = () => {
  window.open('tel:01325 341 500');
};

const ContactText = () => (
  <span>
    Call
    <br />
    <span
      role="link"
      onClick={() => loadPhoneNumber()}
      onKeyPress={() => loadPhoneNumber()}
      tabIndex={0}
      style={{ fontSize: '30px' }}
    >
      01325 341 500
    </span>
  </span>
);

export const ServiceCategorySection = ({ activePageCategory }) => {
  const services = useStaticQuery(graphql`
    {
      allWpService(
        filter: {
          slug: {
            in: [
              "your-home"
              "you-and-your-family"
              "farms-estates"
              "business-commercial"
            ]
          }
        }
      ) {
        edges {
          node {
            id
            title
            link
            menuOrder
            slug
            serviceAcf {
              card {
                slogan
                image {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <StyledContainer>
      <StyledServiceCategorySection>
        <Container>
          <Grid gutter={48}>
            {services.allWpService.edges.map((edge, index) => (
              <Cell
                cellSize={{ tablet: 6, desktop: 6, widescreen: 3 }}
                key={edge.node.id}
              >
                <ServiceCard
                  index={index}
                  category={edge.node.slug}
                  title={edge.node.title}
                  slug={edge.node.slug}
                  slogan={
                    activePageCategory === edge.node.slug ? (
                      <ContactText />
                    ) : (
                      <SloganText slogan={edge.node.serviceAcf.card.slogan} />
                    )
                  }
                  image={
                    edge.node.serviceAcf.card.image
                      ? edge.node.serviceAcf.card.image.sourceUrl
                      : ''
                  }
                  isContactCard={activePageCategory === edge.node.slug}
                />
              </Cell>
            ))}
          </Grid>
        </Container>
      </StyledServiceCategorySection>
    </StyledContainer>
  );
};
