import React, { Component } from 'react';
import Map, { GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

export class MapContainer extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
  };

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });
  };

  onClose = () => {
    const { showingInfoWindow } = this.state;

    if (showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
    }
  };

  render() {
    const { google } = this.props; // eslint-disable-line
    const { activeMarker, showingInfoWindow, selectedPlace } = this.state;

    return (
      <Map
        google={google}
        zoom={15}
        initialCenter={{
          lat: 54.525760,
          lng: -1.554283,
        }}
      >
        <Marker
          onClick={this.onMarkerClick}
          name="Latimer Hinks Solicitors"
        />
        <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>{selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
})(MapContainer);
