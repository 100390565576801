import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import config from '../../../helpers/styles/theme';

const StyledServiceCard = styled.div`
  .ServiceCard {
    margin-bottom: 24px;
    position: relative;
    overflow: hidden;

    @media screen (min-width: 768px) and (max-width: 1300px) {
      margin-bottom: ${props => (props.index <= 1 ? '24px' : '0')};
    }

    @media screen and (min-width: 1300px) {
      margin-bottom: 0;
    }
  }

  .ServiceCard::after {
    background: #eaeaea;
    bottom: 0;
    content: '';
    display: block;
    height: 30%;
    left: 0;
    position: absolute;
    right: -6px;
    transform: skewY(-45deg);
    transform-origin: 100%;
    z-index: 1;
  }

  .ServiceCard__inner {
    ${({ category, image, isContactCard }) =>
      isContactCard
        ? `background-color: ${config.scheme[category]};`
        : `background-image: url('${image}');`}
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
    overflow: hidden;
    position: relative;
    padding: 18px 16px 8px;
    border-right: 1px solid #eaeaea;
  }

  .ServiceCard__inner::after {
    background: #eaeaea;
    bottom: 0;
    content: '';
    display: block;
    height: 30%;
    left: 0;
    position: absolute;
    right: -6px;
    transform: skewY(-45deg);
    transform-origin: 100%;
    z-index: 1;
  }

  .ServiceCard__title {
    color: ${props => config.scheme[props.category]};
    font-size: 20px;
    letter-spacing: -1px;
  }

  .ServiceCard__slogan {
    color: #ffffff;
    font-size: 16px;
    font-weight: 200;

    p {
      line-height: 1.4;
    }

    ${({ isContactCard }) => (isContactCard ? 'width: 100%;' : 'width: 65%')}
  }

  .ServiceCard__Link {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;
const ServiceCardWrap = ({ isContactCard, children, slug }) => {
  if (isContactCard) {
    return (
      <a href="tel: 01325 341 500" className="ServiceCard">
        {children}
      </a>
    );
  }

  return (
    <div className="ServiceCard">
      <Link to={`/our-services/${slug}`} className="ServiceCard__Link">
        {children}
      </Link>
    </div>
  );
};

const ServiceCard = ({
  image,
  slogan,
  title,
  category,
  index,
  slug,
  isContactCard,
}) => (
  <StyledServiceCard
    image={image}
    category={category}
    index={index}
    isContactCard={isContactCard}
  >
    <ServiceCardWrap isContactCard={isContactCard} slug={slug}>
      <h2
        className="ServiceCard__title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div className="ServiceCard__inner">
        <h3 className="ServiceCard__slogan">{slogan}</h3>
      </div>
    </ServiceCardWrap>
  </StyledServiceCard>
);

ServiceCardWrap.defaultProps = {
  isContactCard: false,
};

ServiceCardWrap.propTypes = {
  children: PropTypes.node.isRequired,
  slug: PropTypes.string.isRequired,
  isContactCard: PropTypes.bool,
};

ServiceCard.defaultProps = {
  isContactCard: false,
};

ServiceCard.propTypes = {
  image: PropTypes.string.isRequired,
  slogan: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  isContactCard: PropTypes.bool,
};

export default ServiceCard;
