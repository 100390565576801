import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const theme = {
  default: {
    color: '#eaeaea',
  },

  contactForm: {
    color: '#000000',
  },
};

const StyledTextareaField = styled.div`
  .TextareaField {
    display: block;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid #707070;
    border-radius: 0;
    color: ${props => theme[props.variation].color};
    height: 150px;
    width: 100%;
    padding: 8px;
    transition: 300ms border ease-out;

    &:focus {
      outline: none;
      border: 1px solid #f58021;
      box-shadow: 0 0 2px #f58021;
      transition: 300ms border ease-out;
    }
    &:placeholder {
      color: #939598;
    }
  }

  .TextareaField__Label,
  .TextareaField__Label-Text {
    display: block;
    margin-bottom: 8px;
  }

  .TextareaField__Error {
    color: red;
  }

  .has-error {
    border: 1px solid red;
  }
`;

const TextareaField = ({
  id,
  label,
  placeholder,
  variation,
  onChange,
  isRequired,
}) => (
  <StyledTextareaField variation={variation}>
    <label className="TextareaField__Label" htmlFor={id}>
      <span className="TextareaField__Label-Text">{label}</span>
      <textarea
        className="TextareaField validate-input-js"
        placeholder={placeholder}
        id={id}
        onChange={onChange}
        required={isRequired}
      />
      <div className="invalid-feedback TextareaField__Error" />
    </label>
  </StyledTextareaField>
);

TextareaField.defaultProps = {
  label: null,
  placeholder: '',
  variation: 'default',
  isRequired: false,
};

TextareaField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variation: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default TextareaField;
