import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrow from '../../../images/icons/dropdown-arrow.svg';

const theme = {
  default: {
    color: '#eaeaea',
  },

  contactForm: {
    color: '#000000',
  },
};

const StyledSelectField = styled.div`
  .SelectField {
    appearance: none;
    border-radius: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0.04);
    background-image: url(${arrow});
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 98% 50%;
    border: 1px solid #707070;
    color: ${props => theme[props.variation].color};
    line-height: 1.5;
    padding: 8px;
    transition: 300ms border ease-out;
    width: 100%;

    &:focus {
      outline: none;
      border: 1px solid #f58021;
      box-shadow: 0 0 2px #f58021;
      transition: 300ms border ease-out;
    }

    &:placeholder {
      color: #939598;
    }
  }

  .SelectField__Label,
  .SelectField__Label-Text {
    display: block;
    margin-bottom: 8px;
  }
`;

const SelectField = ({ children, id, label, onChange, variation }) => (
  <StyledSelectField variation={variation}>
    <label htmlFor={id} className="SelectField__Label">
      <span className="SelectField__Label-Text">{label}</span>
      <select className="SelectField" onBlur={onChange} name={id} id={id}>
        {children}
      </select>
    </label>
  </StyledSelectField>
);

SelectField.defaultProps = {
  onChange: null,
  label: '',
  variation: 'default',
};

SelectField.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  variation: PropTypes.string,
};

export default SelectField;
