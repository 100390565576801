import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Grid, Cell } from '../global/grid';

import Checkbox from '../atoms/checkbox/Checkbox';
import SelectField from '../atoms/select-field/SelectField';
import TextareaField from '../atoms/textarea-field/TextareaField';

import InputField from '../common/text-field/InputField';
import Button from '../common/button/Button';

import StyledContactForm from './StyledContactForm';

// Sub services dependent on the main service
const subServices = {
  'You and your family': [
    'Powers of attorney, court of protection & capacity',
    'Wills',
    'Trusts',
    'Trust management',
    'Tax planning',
    'Probate, intestacy & estate administration',
    'Elderly & vulnerable client issues',
    'Personal affairs service',
  ],
  'Your home': [
    'Freehold sales & purchases',
    'Leasehold sales and purchases & lease extensions',
    'Mortgages & remortgages',
    'New builds',
    'Barn conversions & developments',
  ],
  'Farms and estates': [
    'Agricultural sales, purchases & options',
    'Agricultural tenancies',
    'Succession planning',
    'Farming partnership agreements',
    'Land use diversification, planning & development',
    'Minerals',
    'Forestry & woodland',
    'Renewable energy',
  ],
  'Business and commercial': [
    'Succession planning',
    'Business expansion',
    'Employment law',
    'Commercial contracts',
    'Selling your business',
    'Wills, probate/estates, trusts & tax planning',
    'Buying & selling commercial property',
    'Landlord & tenant',
    'Development',
    'Telecoms',
  ],
};

class ContactForm extends Component {
  state = {
    form: {
      name: '',
      email: '',
      tel: '',
      botCheck: '',
      service: 'You and your family',
      subService: 'Divorce',
      message: '',
      contact_by_email: false,
      contact_by_phone: false,
    },
  };

  validate = () => {
    const formLength = this.formEl.length;

    if (this.formEl.checkValidity() === false) {
      for (let i = 0; i < formLength; i++) {
        const elem = this.formEl[i];
        const errorLabel = elem.parentNode.querySelector('.invalid-feedback');

        if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
          if (!elem.validity.valid) {
            elem.parentNode
              .querySelector('.validate-input-js')
              .classList.add('has-error');
            errorLabel.textContent = elem.validationMessage;
          } else {
            errorLabel.textContent = '';
            elem.parentNode
              .querySelector('.validate-input-js')
              .classList.remove('has-error');
          }
        }
      }
      return false;
    }

    for (let i = 0; i < formLength; i++) {
      const elem = this.formEl[i];
      const errorLabel = elem.parentNode.querySelector('.invalid-feedback');

      if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
        errorLabel.textContent = '';
        elem.parentNode
          .querySelector('.validate-input-js')
          .classList.remove('has-error');
      }

      if (elem.id === 'validation') {
        if (elem.value !== '24') {
          elem.parentNode
            .querySelector('.validate-input-js')
            .classList.add('has-error');
          errorLabel.textContent = 'Please check your answer and try again';

          return false;
        }
      }
    }

    return true;
  };

  setFormKey = (key, value) => {
    this.setState(({ form }) => ({
      form: {
        ...form,
        [key]: value,
      },
    }));
  };

  submitForm = e => {
    e.preventDefault();

    const { onSubmit } = this.props;
    const { form } = this.state;

    if (this.validate()) {
      onSubmit(form);
    }
  };

  render() {
    return (
      <StyledContactForm
        onSubmit={e => this.submitForm(e)}
        ref={form => (this.formEl = form)}
        noValidate
      >
        <h2>Get in touch</h2>
        <Grid gutter={16}>
          <Cell cellSize={{ widescreen: 6, desktop: 6, tablet: 6 }}>
            <InputField
              type="text"
              placeholder="Name*"
              variation="contactForm"
              name="name"
              id="name"
              onChange={e => this.setFormKey('name', e.target.value)}
              isRequired
            />
          </Cell>

          <Cell cellSize={{ widescreen: 6, desktop: 6, tablet: 6 }}>
            <InputField
              type="email"
              placeholder="Email*"
              variation="contactForm"
              name="email"
              id="email"
              onChange={e => this.setFormKey('email', e.target.value)}
              isRequired
            />
          </Cell>

          <Cell cellSize={{ widescreen: 6, desktop: 6, tablet: 6 }}>
            <InputField
              type="tel"
              placeholder="Contact No."
              variation="contactForm"
              name="tel"
              id="tel"
              onChange={e => this.setFormKey('tel', e.target.value)}
            />
          </Cell>

          <Cell cellSize={{ widescreen: 6, desktop: 6, tablet: 6 }}>
            <InputField
              type="text"
              placeholder="What is 19 + 5?*"
              variation="contactForm"
              name="validation"
              id="validation"
              isRequired
            />
          </Cell>

          <Cell cellSize={{ widescreen: 6, desktop: 6, tablet: 6 }}>
            <SelectField
              variation="contactForm"
              id="service"
              onChange={e => this.setFormKey('service', e.target.value)}
            >
              <option value="You and your family">You &amp; your family</option>
              <option value="Your home">Your home</option>
              <option value="Farms and estates">Farms &amp; estates</option>
              <option value="Business and commercial">
                Business &amp; commercial
              </option>
            </SelectField>
          </Cell>

          <Cell cellSize={{ widescreen: 6, desktop: 6, tablet: 6 }}>
            <SelectField variation="contactForm" id="sub-service">
              <option disabled selected>
                Please select
              </option>
              {subServices[this.state.form.service].map((subject, index) => (
                <option value={subject} key={index}>
                  {subject}
                </option>
              ))}
            </SelectField>
          </Cell>

          <Cell>
            <TextareaField
              variation="contactForm"
              placeholder="Queries/Comments*"
              id="message"
              onChange={e => this.setFormKey('message', e.target.value)}
              isRequired
            />
          </Cell>

          <Cell
            cellSize={{ widescreen: 3, desktop: 3 }}
            cellOrder={{ phone: 2 }}
          >
            <p className="ContactForm-OptIn">Please Contact me by:</p>
          </Cell>

          <Cell
            cellSize={{ widescreen: 2, desktop: 2, tablet: 4 }}
            cellOrder={{ phone: 3 }}
          >
            <Checkbox
              label="Telephone"
              onChange={e =>
                this.setFormKey('contact_by_phone', e.target.checked)
              }
            />
          </Cell>

          <Cell
            cellSize={{
              widescreen: 2,
              desktop: 2,
              tablet: 4,
            }}
            cellOrder={{ phone: 4 }}
          >
            <Checkbox
              label="Email"
              onChange={e =>
                this.setFormKey('contact_by_email', e.target.checked)
              }
            />
          </Cell>
          <Cell
            cellSize={{ widescreen: 5, desktop: 5, tablet: 4 }}
            cellOrder={{ phone: 1 }}
          >
            <p>* Indicates mandatory field</p>
          </Cell>

          <Cell
            cellSize={{ widescreen: 3, desktop: 3, tablet: 6 }}
            cellOrder={{ phone: 5 }}
            cellAlign="center"
          >
            <Button type="Submit" label="Send" variation="contactForm" />
          </Cell>
        </Grid>
      </StyledContactForm>
    );
  }
}

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ContactForm;
