import styled from 'styled-components';

export default styled.form`
  color: #939598;

  h2 {
    color: #f58021;
    margin-bottom: 24px;
    @media screen and (min-width: 768px) {
      font-size: 32px;
    }
  }
  .ContactForm-OptIn {
    font-size: 16px;

    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
`;
