import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckboxContent from './StyledCheckbox';

const StyledCheckboxContent = styled.label`
  .CheckboxContent {
    display: inline-block;
  }

  span {
    position: relative;
    font-size: 16px;
    bottom: 1px;

    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
`;

class Checkbox extends Component {
  state = { checked: false }

  handleCheckboxChange = (e) => {
    const { onChange } = this.props;

    this.setState({ checked: e.target.checked });

    onChange(e);
  }

  render() {
    const { checked } = this.state;
    const { label } = this.props;

    return (
      <StyledCheckboxContent>
        <CheckboxContent
          className="CheckboxContent"
          checked={checked}
          onChange={this.handleCheckboxChange}
        />
        <span>{label}</span>
      </StyledCheckboxContent>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
