import styled from 'styled-components';

const GenericCard = styled.div`
  background-color: #eaeaea;
  margin-bottom: 48px;
  max-width: 1480px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    margin-bottom: 32px;
  }

  .GenericCard--ArticlePage,
  .GenericCard--IndividualArticle {
    padding: 24px;

    @media screen and (min-width: 768px) {
      padding: 54px;
      margin-top: -400px;
    }

    .ArticlePage__Date {
      font-family: lato;
      font-size: 32px;
      color: #939598;
      margin-bottom: 24px;
    }
  }

  .GenericCard--ContactPage {
    padding: 32px;

    @media screen and (min-width: 768px) {
      padding: 54px;
    }
  }
`;

export default GenericCard;
