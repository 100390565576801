import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
`;

const NativeCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
`;

const CheckboxTick = styled.div`
  position: relative;
  bottom: 3px;
  left: 2px;
  color: #707070;
  font-size: 16px;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    bottom: 4px;
    left: 3px;
  }
`;

const StyledCheckbox = styled.div`
  position: relative;
  background: ${props => (props.checked ? '#ffffff' : '#eaeaea')};
  border: ${props => (props.checked ? '1px solid #f58021' : '1px solid #707070')};
  box-shadow: ${props => (props.checked ? '0 0 2px #f58021' : 'none')};
  height: 16px;
  width: 16px;
  top: 3px;
  margin-right: 8px;
  margin-bottom: 16px;
  @media screen and (min-width: 768px) {
    height: 22px;
    width: 22px;
    margin-bottom: 40px;
  }

  ${CheckboxTick} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`;

const CheckboxContent = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <NativeCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <CheckboxTick>&#10003;</CheckboxTick>
    </StyledCheckbox>
  </CheckboxContainer>
);

CheckboxContent.defaultProps = {
  checked: false,
};

CheckboxContent.propTypes = {
  className: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default CheckboxContent;
