import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { ServiceCategorySection } from '../components/common/service-category-section';
import { Grid, Cell } from '../components/global/grid';
import Layout from '../components/layout';
import Hero from '../components/common/hero/Hero';
import StaticHeroContainer from '../components/atoms/hero/StaticHeroContainer';
import AddressGrid from '../components/common/address-grid/AddressGrid';
import Container from '../components/global/container/Container';
import GoogleMapsMap from '../components/common/google-maps/GoogleMapsMap';
import GenericCard from '../components/common/generic-card/GenericCard';
import ContactForm from '../components/contact-form/ContactForm';
import Divider from '../components/atoms/divider/Divider';
import DirectionButton from '../components/atoms/buttons/direction-button/DirectionButton';

const StyledSubmitMessage = styled.div`
  position: relative;
  background-color: ${(props) => (props.isError ? '#ca7575' : '#75ca91')};
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 16px;
  padding: 16px;
  padding-right: 40px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }

  button {
    position: absolute;
    right: 16px;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    top: 50%;
    padding-bottom: 6px;
    transform: translateY(-50%);
  }

  p {
    margin-bottom: 0;
  }
`;

const ContactUsPage = () => {
  const [showSuccessMessage, setSuccessMessage] = useState(false);
  const [showErrorMessage, setErrorMessage] = useState(false);

  const handleFormSubmission = async (payload) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'User-Agent': process.env.REACT_APP_NETLIFY_USER_AGENT,
        },
        body: JSON.stringify({
          ...payload,
        }),
      };

      const response = await fetch('/.netlify/functions/send-enquiry', options);

      if (response.ok) {
        setSuccessMessage(true);
        setErrorMessage(false);
      } else {
        setErrorMessage(true);
      }
    } catch (err) {
      console.error('handleFormSubmission() Error:', err);
      setErrorMessage(true);
    }
  };

  return (
    <Layout path="/contact-us">
      <StaticQuery
        query={graphql`
          {
            wpPage(slug: { eq: "contact-internal" }) {
              featuredImage {
                node {
                  sourceUrl
                }
              }
            }
          }
        `}
        render={(data) => (
          <Hero
            isLandingPage
            backgroundImageUrl={data.wpPage.featuredImage.node.sourceUrl}
            category="generic"
          >
            <Container isFullWidth>
              <StaticHeroContainer category="generic" hideBlock noSideSpacing>
                <Grid gutter={16}>
                  <Cell>
                    <div className="StaticHeroContainer__Title">
                      <h1>Contact us</h1>
                    </div>
                  </Cell>
                  <Cell
                    cellSize={{ widescreen: 3, desktop: 3, tablet: 4 }}
                    cellOrder={{ phone: 2 }}
                  >
                    <DirectionButton
                      as="a"
                      href="https://goo.gl/maps/S5WoR8G7kXh6RzJN6"
                      target="_blank"
                    >
                      <div className="MapMarkerIcon" />
                      <span>Get directions</span>
                    </DirectionButton>

                    <DirectionButton
                      as="a"
                      href="https://latimer-hinks.the-staging.co.uk/wp-content/uploads/2020/03/Directions-Latimer-Hinks-Solicitors_Darlington-DL1-1NL_How-to-Find-Us.pdf"
                      target="_blank"
                    >
                      View directions
                    </DirectionButton>
                  </Cell>
                  <Cell
                    cellSize={{ widescreen: 9, desktop: 9, tablet: 8 }}
                    cellOrder={{ phone: 1 }}
                  >
                    <div
                      className="
                      StaticHeroContainer__Foreword
                      StaticHeroContainer__Foreword--Generic
                      StaticHeroContainer__Foreword--Contact
                    "
                    >
                      <AddressGrid />
                    </div>
                  </Cell>
                </Grid>
              </StaticHeroContainer>
            </Container>
          </Hero>
        )}
      />

      <Container>
        <GoogleMapsMap />
        <GenericCard>
          <div className="GenericCard--ContactPage">
            {showErrorMessage && (
              <StyledSubmitMessage isError>
                <p>
                  There was a problem submitting your enquiry. Please try again
                  or call us on 01325 341 500
                </p>
                <button type="button" onClick={() => setErrorMessage(false)}>
                  x
                </button>
              </StyledSubmitMessage>
            )}
            {showSuccessMessage && (
              <StyledSubmitMessage>
                <p>
                  We have received your enquiry and will be in touch with you
                  shortly.
                </p>
                <button type="button" onClick={() => setSuccessMessage(false)}>
                  x
                </button>
              </StyledSubmitMessage>
            )}
            <ContactForm
              onSubmit={(payload) => handleFormSubmission(payload)}
            />
          </div>
        </GenericCard>
      </Container>

      <ServiceCategorySection />

      <Divider isNarrow />
    </Layout>
  );
};

export default ContactUsPage;
